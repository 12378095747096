import React, { useEffect, useState, useRef, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import decodeJwt from 'jwt-decode'
import projectXUI from 'project-x-ui'
import FlipMove from 'react-flip-move'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDebouncedLoader } from 'use-debounced-loader'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import CloseIcon from '@mui/icons-material/Close'

import { Coin } from '../Coin'

import { useMixpanel } from '../../../../../../hooks/mixpanel'

import { handleTwitterLogin } from '../ActionsPanel/ActionsPanel'

import { useSrpcApi } from '../../../../../../hooks/useSrpcApi'

import { useGroups } from '../../../../../../context/groups'

const {
  leafs: { QuestionCard },
  shallow: { QuestionCardsRow },
  context: { MainScreenSwipeContext }
} = projectXUI

const Reward = ({ reward }) => {
  return (
    (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 4,
          width: '100%',
          alignItems: 'center',
          position: 'relative',
          whiteSpace: 'nowrap'
        }}
      >
        🎉 You got reward:
        <span
          style={{
            maxWidth: 75,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '1.2em',
            fontWeight: 500,
            marginLeft: 8
          }}
          title={reward}
          alt={reward}
        >
          {reward}
        </span>
        <Coin />
      </div>
    )
  )
}

QuestionCard.prototype = {}

export const MessageList = ({ jwt, onUserClick }) => {
  const [messages, setMessages] = useState(null)
  const sortAndFilterForMessages = useSelector(state => state.sortAndFilterForMessages)
  const [offset, setOffset] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const ref = useRef()
  const [loading, setLoading] = useState(true)
  const debouncedIsLoading = useDebouncedLoader(loading, 0, 1000)
  const navigate = useNavigate()
  const { mixpanel } = useMixpanel()
  const [respondLoading, setRespondLoading] = useState(false)
  const dispatch = useDispatch()
  const [questionSelectedForGroupMode, setQuestionSelectedForGroupMode] = useState(false)
  const [comments, setComments] = useState([])
  const [questionForComments, setQuestionForComments] = useState(null)
  const { setNewGroup } = useGroups()

  let user

  if (jwt) {
    const jwtDecoded = decodeJwt(jwt)
    user = jwtDecoded
  }

  const srpcApi = useSrpcApi()

  const { setIsModalOpen, setShowGroups } = useContext(MainScreenSwipeContext)

  useEffect(() => {
    if (sortAndFilterForMessages.filter && sortAndFilterForMessages.sort) {
      fetchMessages({ offset: 0, reset: true })
    }
  }, [jwt, sortAndFilterForMessages.filter, sortAndFilterForMessages.sort])

  const getUsersByAnswer = async (questionId, answer) => {
    navigate(`/questions/${questionId}/${answer}/users`)
  }

  const respond = async (messageId, content) => {
    if (!jwt) {
      console.log('cannot respond without being authorized')
      return null
    }
    setRespondLoading(true)

    mixpanel.current.track('respond', {
      questionId: messageId,
      answer: content
    })

    const { reward } = await srpcApi.saveMessage({ jwt, content, parentMessageId: messageId })

    setMessages(messages.map(m =>
      m._id === messageId
        ? ({ ...m, me: { answer: content, pictureUrl: user?.pictureUrl }, answersCount: { ...m.answersCount, [content]: m.answersCount[content] + 1 } })
        : m
    ))

    setRespondLoading(false)

    if (!window.featureFlags?.rewards) {
      return
    }

    toast(() => (
      <div
        style={{ fontFamily: 'IBM Plex Sans', display: 'flex', flexDirection: 'column', gap: 24, width: 320, boxSizing: 'border-box', padding: '0 9px' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          {reward
            ? <Reward reward={reward} />
            : (
                    <p style={{ fontWeight: 'bold' }}>
                      📢{' '}
                      <button
                        className='connect-wallet-toast-button'
                        onClick={() => { toast.dismiss(); setIsModalOpen(true) }}
                      >
                        Connect wallet
                      </button>
                      &nbsp; to get rewards
                    </p>
              )
          }
          <CloseIcon style={{ cursor: 'pointer' }} onClick={() => toast.dismiss()} />
        </div>
        {window.featureFlags?.rewards && (!reward && <p>*reward💰 is in proportion⚖️ to how much 𝕏 followers👨‍👦‍👦 you have!</p>)}
      </div>
    ), { duration: Infinity })
  }

  const fetchMessages = async ({ offset, reset }) => {
    if (!loading) {
      setLoading(true)
    }
    if (reset) {
      ref.current?.scrollTo(0, 0)
    }
    const { messages: newMessages, hasMore } = await srpcApi.getMessages({
      jwt, offset, sort: sortAndFilterForMessages.sort, filter: sortAndFilterForMessages.filter
    })
    setLoading(false)
    if (offset) {
      mixpanel.current.track('Load More Questions')
    }
    setMessages(reset ? newMessages : [...messages, ...newMessages])

    if (!hasMore) {
      setHasMore(false)
    } else {
      setOffset(offset + 10)
    }
  }

  const onQuestionClick = id => {
    navigate(`/questions/${id}`)
  }

  const addImage = (messageId, img) => {
    srpcApi.addImage({ jwt, img, messageId })
  }

  const selectedForComposition = useSelector(state => state.selectedForCompositionGroupIds)

  const createGroup = (messageId, content) => {
    if (selectedForComposition.length !== 0) {
      return
    }

    const newGroup = {
      messageId,
      content,
      userCount: messages.find(m => m._id === messageId).answersCount[content],
      users: []
    }

    setNewGroup(newGroup)

    setQuestionSelectedForGroupMode(null)
  }

  return (
    <QuestionCardsRow id='questions-desktop-scroll-target' style={{ position: 'relative' }} ref={ref}>
      {messages && messages.length === 0 && (
        <div
          style={{
            fontFamily: 'IBM Plex Sans',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            textAlign: 'center'
          }}
        >
          <span>Empty</span>
        </div>
      )}
      <InfiniteScroll
        scrollableTarget='questions-desktop-scroll-target'
        dataLength={messages?.length || 0}
        next={() => fetchMessages({ offset })}
        hasMore={hasMore}
        style={{ position: 'relative' }}
      >
        <FlipMove
          typeName={null}
          maintainContainerHeight
        >
          {debouncedIsLoading
            ? []
            : messages?.filter(i => !i.hide).map(m => {
              const groupMode = questionSelectedForGroupMode === m._id
              return (
                <QuestionCard
                  key={m._id}
                  name={m.content}
                  answersCount={m.answersCount}
                  addImage={img => addImage(m._id, img)}
                  img={m.img}
                  me={m.me}
                  shortId={m.shortId}
                  handleTwitterLogin={handleTwitterLogin}
                  parentMessageId={m._id}
                  createdAt={m.createdAt}
                  respond={jwt ? content => respond(m._id, content) : null}
                  onUserClick={() => onUserClick({ _id: m.userId })}
                  createNewGroup={groupMode ? content => { console.log('createGroup'); createGroup(m._id, content); setShowGroups(true) } : content => getUsersByAnswer(m.shortId, content)}
                  yourOwnQuestion={user?.userId === m.userId}
                  username={m.username}
                  onClick={() => onQuestionClick(m.shortId)}
                  addImageAvailable={user?._id === m.userId}
                  loading={respondLoading}
                  toggleGroupMode={() => setQuestionSelectedForGroupMode(state => state === m._id ? null : m._id)}
                  groupMode={groupMode}
                  showCommentsForQuestion={m._id === questionForComments}
                  comments={comments}
                  user={user}
                  saveComment={async text => {
                    const newComment = { jwt, text, questionId: m._id, answer: m.me.answer, createdAt: new Date(), difference: 30 }
                    const { newComment: r } = await srpcApi.saveComment(newComment)
                    setComments([r, ...comments])
                  }}
                  fetchComments={async () => {
                    const { comments } = await srpcApi.getComments({ jwt, questionId: m._id })
                    setComments(comments)
                    setQuestionForComments(m._id)
                  }}
                />
              )
            })}
        </FlipMove>
      </InfiniteScroll>
    </QuestionCardsRow>
  )
}
