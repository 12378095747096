/* eslint-disable */
import React, { useContext, useEffect } from 'react'
import projectXUI from 'project-x-ui'
import { useSelector, useDispatch } from 'react-redux'
import FlipMove from 'react-flip-move'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useNavigate } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { useLocalStorage } from '@rehooks/local-storage'
import { useMediaQuery } from 'react-responsive'

import GroupList from '../GroupList'
import GroupComposition from '../GroupComposition'

import { SET_SORT_AND_FILTER_FOR_MESSAGES } from '../../../../../../actions/setSortAndFilterForMessages'

import { useMixpanel } from '../../../../../../hooks/mixpanel'
import { useGroups } from '../../../../../../context/groups'

const {
  leafs: { Search, SortQuestions },
  context: { MainScreenSwipeContext }
} = projectXUI

Search.prototype = {}
SortQuestions.prototype = {}
GroupList.prototype = {}
GroupComposition.prototype = {}

const initialState = { sort: 'Most Answered', filter: 'All Time' }

export const SortAndSearch = ({ jwt }) => {
  const { screenName, showSearch, setShowSearch, showGroups, showSearchMenu, setShowSearchMenu } = useContext(MainScreenSwipeContext)
  const questionsSearchResult = useSelector(state => state.questionsSearchResult)
  const sortAndFilterForMessages = useSelector(state => state.sortAndFilterForMessages)
  const { groups, newGroup } = useGroups()

  const navigate = useNavigate();
  const showOnlyGroups = useMediaQuery({ query: '(max-width: 1468px)' })
  const showOnlySearchMenu = useMediaQuery({ query: '(max-width: 1200px)' })

  const dispatch = useDispatch()
  const { mixpanel } = useMixpanel()

  const ref = useDetectClickOutside({ onTriggered: () => { setShowSearch(false); setShowSearchMenu(false) } })

  const [sortAndFilter, setSortAndFilterLS] = useLocalStorage('sortAndFilter')

  useEffect(() => {
    if (sortAndFilter) {
      setSortAndFilter(sortAndFilter.sort, sortAndFilter.filter, false)
    } else {
      setSortAndFilter(initialState.sort, initialState.filter, false)
    }
  }, [Boolean(sortAndFilter)])

  const searchFn = async (entity, search) => {
    if (entity === 'Questions') {
      navigate(`/questions?search=${search}`)
    } else {
      navigate(`/users?search=${search}`)
    }

    setShowSearch(false)
  }

  const setSortAndFilter = (sort, filter, update = true) => {
    dispatch({
      type: SET_SORT_AND_FILTER_FOR_MESSAGES,
      payload: { sort: sort || sortAndFilterForMessages.sort , filter: filter || sortAndFilterForMessages.filter }
    })
    if (update) {
      setSortAndFilterLS(JSON.stringify({ sort: sort || sortAndFilterForMessages.sort , filter: filter || sortAndFilterForMessages.filter }))
    }
    setShowSearch(false)
  }

  if (screenName === 'uninitialized') {
    return null
  }

  return (
    <>
      <BottomSheet open={screenName && (showSearch || showSearchMenu)} initialFocusRef={false}>
        <div ref={ref}>
          <div style={{ height: 415, width: '100%', display: 'flex', justifyContent: 'center' }} ref={ref}>
            <div style={{ width: 241 }}>
              {/* <FlipMove typeName={null} appearAnimation={'fade'} enterAnimation={'fade'} leaveAnimation={'fade'}> */}
                <Search buttonsOutside search={searchFn} />
                <SortQuestions
                  sort={sortAndFilterForMessages.sort}
                  duration={sortAndFilterForMessages.filter}
                  setSort={sort => setSortAndFilter(sort)}
                  setDuration={duration => setSortAndFilter(null, duration)}
                  getMessages={setSortAndFilter}
                  disabledDuration={questionsSearchResult.search}
                />
              {/* </FlipMove> */}
            </div>
          </div>
        </div>
      </BottomSheet>
      {showGroups && screenName && (
        <div style={{ marginTop: 10, width: '100%', padding: '0 10px' }}>
          <GroupComposition />
          <GroupList jwtMock={jwt} />
        </div>
      )}
      {
        !screenName && (
          <div
            style={{
              width: 241,
              position: 'absolute',
              right: showOnlySearchMenu ? 'auto' : 178,
              transform: 'translateX(-50%)'
            }}
          >
            <FlipMove typeName={null} appearAnimation={'fade'} enterAnimation={'fade'} leaveAnimation={'fade'}>
              {showSearchMenu && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Search buttonsOutside search={searchFn} />
                  <SortQuestions
                    sort={sortAndFilterForMessages.sort}
                    duration={sortAndFilterForMessages.filter}
                    setSort={sort => {
                      mixpanel.current.track('Update Sort', {
                        sort
                      })
                      setSortAndFilter(sort)
                    }}
                    setDuration={duration => {
                      mixpanel.current.track('Update Filter', {
                        filter: duration
                      })
                      setSortAndFilter(null, duration)
                    }}
                    getMessages={setSortAndFilter}
                    disabledDuration={questionsSearchResult.search}
                  />
                  <button
                    className='reset-filters-button'
                    onClick={() => {
                      mixpanel.current.track('Reset Filter and Sort')
                      setSortAndFilter(initialState.sort, initialState.filter)
                    }}
                  >
                    reset
                  </button>
                </div>
              )}
            </FlipMove>
          </div>
        )
      }
      {
        window.featureFlags?.groups && !screenName && (
          <div
            style={{
              width: 380,
              position: 'absolute',
              right: showOnlyGroups ? 'auto' : 108,
              transform: 'translateX(-50%)',
              marginTop: 10
            }}
          >
            <FlipMove typeName={null} appearAnimation={'fade'} enterAnimation={'fade'} leaveAnimation={'fade'}>
              {showGroups && (
                <div style={{
                  border: (groups && groups.length === 0 && !showOnlyGroups && !newGroup) ? '1px solid #b3b3b3' : 'none',
                  borderRadius: 14,
                  height: 'calc(100dvh - 20px)',
                }}>
                  <GroupComposition />
                  <GroupList jwtMock={jwt} />
                </div>
              )}
            </FlipMove>
          </div>
        )
      }
    </>
  )
}
