import React, { createContext, useContext, useState } from 'react'

const GroupsContext = createContext()

export const GroupsProvider = ({ children }) => {
  const [groups, setGroups] = useState(null)
  const [newGroup, setNewGroup] = useState(null)
  const addGroup = (newGroup) => {
    setGroups(groups => ([newGroup, ...groups]))
  }
  return (
    <GroupsContext.Provider value={{ groups, setGroups, newGroup, setNewGroup, addGroup }}>
      {children}
    </GroupsContext.Provider>
  )
}

export const useGroups = () => {
  const context = useContext(GroupsContext)
  if (!context) {
    throw new Error('useGroups must be used within a GroupsProvider')
  }
  return context
}
