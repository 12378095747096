import React from 'react'

import coinSvg from './assets/dollar.svg?url'

import './styles.css'

export const Coin = () => {
  return (
    <div style={{ position: 'relative', height: 24, left: -13 }}>
      <div
        className="coin coin--animated"
        style={{ '--coin-to-x': 'calc(-100px + 24px)', '--coin-to-y': 'calc(-105px + 24px)', '--coin-delay': '0.3s' }}
      />
      <div
        className="coin coin--animated"
        style={{ '--coin-to-x': 'calc(-70px + 24px)', '--coin-to-y': '-90px', '--coin-delay': '0.1s' }}
      />
      <div
        className="coin coin--animated"
        style={{ '--coin-to-x': 'calc(-30px + 24px)', '--coin-to-y': '-125px', '--coin-delay': '0s' }}
      />
      <div
        className="coin coin--animated"
        style={{ '--coin-to-x': 'calc(10px + 24px)', '--coin-to-y': '-130px', '--coin-delay': '0.2s' }}
      />
      <div
        className="coin coin--animated"
        style={{ '--coin-to-x': 'calc(30px + 24px)', '--coin-to-y': '-100px', '--coin-delay': '0.1s' }}
      />
      <div
        className="coin coin--animated"
        style={{ '--coin-to-x': 'calc(70px + 24px)', '--coin-to-y': '-95px', '--coin-delay': '0.4s' }}
      />
      <div
        className="coin coin--animated"
        style={{ '--coin-to-x': 'calc(100px + 24px)', '--coin-to-y': '-100px', '--coin-delay': '0.2s' }}
      />
      <div>
        <img
          style={{
            height: 24,
            width: 54,
            boxSizing: 'border-box',
            padding: '0 15px'
          }}
          src={coinSvg}
          alt="coin"
        />
      </div>
    </div>
  )
}
