[
  {
    "link": "https://poll.cc/questions/0",
    "title": "Do you support Trump?"
  },
  {
    "link": "https://poll.cc/questions/1",
    "title": "Why I should support him?"
  },
  {
    "link": "https://poll.cc/questions/2",
    "title": "Do you think Ukraine will win in the war ?"
  },
  {
    "link": "https://poll.cc/questions/6",
    "title": "Are you willing to pay more taxes so other people can have food and heathcare?"
  },
  {
    "link": "https://poll.cc/questions/9",
    "title": "Do you think Biden will win the election?"
  },
  {
    "link": "https://poll.cc/questions/12",
    "title": "Will this app be successful?"
  },
  {
    "link": "https://poll.cc/questions/13",
    "title": "Do you think no one is above the law in US?"
  },
  {
    "link": "https://poll.cc/questions/14",
    "title": "Would you vote for Nigel Farage as PM in the next General Election?"
  },
  {
    "link": "https://poll.cc/questions/15",
    "title": "Do you think Fauci should be prosecuted?"
  },
  {
    "link": "https://poll.cc/questions/16",
    "title": "Are you working because you want to?"
  },
  {
    "link": "https://poll.cc/questions/17",
    "title": "What do you think, will Democrats cheat in the next election?"
  },
  {
    "link": "https://poll.cc/questions/18",
    "title": "🚨Would you still vote for Trump in November if he was in a jail cell?"
  },
  {
    "link": "https://poll.cc/questions/19",
    "title": "Would you support Tucker Carlson being apart of Donald Trump’s administration ?"
  },
  {
    "link": "https://poll.cc/questions/20",
    "title": "Do you think the 2020 election was stolen from President Donald Trump??"
  },
  {
    "link": "https://poll.cc/questions/42",
    "title": "Are you here from Twitter/X ads?"
  },
  {
    "link": "https://poll.cc/questions/50",
    "title": "Should Hassan reimburse people that invested in crypto he promoted?"
  },
  {
    "link": "https://poll.cc/questions/51",
    "title": "Do you think Trump(yes) or Biden(no) won first presidential debate?"
  },
  {
    "link": "https://poll.cc/questions/57",
    "title": "Do you think Y combinator will accept me?"
  },
  {
    "link": "https://poll.cc/questions/58",
    "title": "Do you think attack on titan ending was a good one?"
  },
  {
    "link": "https://poll.cc/questions/78",
    "title": "Do you think that Demon Slayer Season 5 has too few episodes? Would you like to see more?"
  },
  {
    "link": "https://poll.cc/questions/79",
    "title": "Is Biden still alive?"
  },
  {
    "link": "https://poll.cc/questions/83",
    "title": "Would you move to the country south of yours for $250,000?"
  },
  {
    "link": "https://poll.cc/questions/82",
    "title": "Do you think killing animals for food is moral?"
  },
  {
    "link": "https://poll.cc/questions/88",
    "title": "Would you rather? $1 or $35?"
  },
  {
    "link": "https://poll.cc/questions/91",
    "title": "Do you think americas hatred for communism is stupid?"
  },
  {
    "link": "https://poll.cc/questions/92",
    "title": "Would you rather take..."
  },
  {
    "link": "https://poll.cc/questions/100",
    "title": "Would you rather meet your?"
  },
  {
    "link": "https://poll.cc/questions/102",
    "title": "Do you know the freezing point of water of the top of your head?"
  },
  {
    "link": "https://poll.cc/questions/103",
    "title": "Would you face your greatest fear for $10 million or just take $1 million?"
  },
  {
    "link": "https://poll.cc/questions/104",
    "title": "Do you think it’s ok for white people to have dreadlocks?"
  },
  {
    "link": "https://poll.cc/questions/108",
    "title": "Would you stick your hand in a deep fat frier for 1 second for £1 billion?"
  },
  {
    "link": "https://poll.cc/questions/109",
    "title": "Do you truly believe that things would be any better if women ran the world?"
  },
  {
    "link": "https://poll.cc/questions/111",
    "title": "Would you rather be stuck in a cage with..?"
  },
  {
    "link": "https://poll.cc/questions/114",
    "title": "Would you click a button that deletes all the porn that has ever existed?"
  },
  {
    "link": "https://poll.cc/questions/118",
    "title": "Would you rather be Rick or Morty? 🤷‍♂️"
  },
  {
    "link": "https://poll.cc/questions/120",
    "title": "Would you mercilessly beat your neighbor in front of his wife and kids for 1 Billion USD?"
  },
  {
    "link": "https://poll.cc/questions/122",
    "title": "Would you live under constant stress for a month for $2,000?"
  },
  {
    "link": "https://poll.cc/questions/127",
    "title": "Do you have an accent?"
  },
  {
    "link": "https://poll.cc/questions/135",
    "title": "Do you have an accent?"
  },
  {
    "link": "https://poll.cc/questions/136",
    "title": "Would you rather take a 1 week vacation anywhere you like or 1 day in the year 3021?"
  },
  {
    "link": "https://poll.cc/questions/141",
    "title": "Do you know how to play chess?"
  },
  {
    "link": "https://poll.cc/questions/144",
    "title": "Would you rather......."
  },
  {
    "link": "https://poll.cc/questions/157",
    "title": "Do you find body hair on women gross?"
  },
  {
    "link": "https://poll.cc/questions/158",
    "title": "Dolphins are what type of class?"
  },
  {
    "link": "https://poll.cc/questions/168",
    "title": "Would you rather...."
  },
  {
    "link": "https://poll.cc/questions/169",
    "title": "Would you smoke meth right now for 10k?"
  },
  {
    "link": "https://poll.cc/questions/171",
    "title": "Would you take $10 Million Dollars right now, or permanently end world hunger?"
  },
  {
    "link": "https://poll.cc/questions/182",
    "title": "Do you think another language should have become the main language instead of English?"
  },
  {
    "link": "https://poll.cc/questions/183",
    "title": "Do you want to change your Reddit username?"
  },
  {
    "link": "https://poll.cc/questions/186",
    "title": "Does America have a Gun Problem?"
  },
  {
    "link": "https://poll.cc/questions/188",
    "title": "Do you think it’s wrong when the English language gets represented by the American flag instead of the English or British flag?"
  },
  {
    "link": "https://poll.cc/questions/190",
    "title": "Do you have a problem with trans people? If so why?"
  },
  {
    "link": "https://poll.cc/questions/191",
    "title": "Do you believe abortion is murder?"
  },
  {
    "link": "https://poll.cc/questions/194",
    "title": "Would you consider killing someone in the sleep a smart move or a coward move?"
  },
  {
    "link": "https://poll.cc/questions/197",
    "title": "Would you rather have your child be a flat earther, or anti-vax?"
  },
  {
    "link": "https://poll.cc/questions/201",
    "title": "Do you think eating meat is bad for the environment?"
  },
  {
    "link": "https://poll.cc/questions/202",
    "title": "Does polictal leaning (left or right) bias how you feel about the Rittenhouse case?"
  },
  {
    "link": "https://poll.cc/questions/205",
    "title": "Does smoking a cigarette make someone look cool?"
  },
  {
    "link": "https://poll.cc/questions/206",
    "title": "Do you think being overweight is a choice?"
  },
  {
    "link": "https://poll.cc/questions/84",
    "title": "Would you rather be rich or famous? 🤷"
  },
  {
    "link": "https://poll.cc/questions/87",
    "title": "Would you kiss your same gender friend for 10000$ ?"
  },
  {
    "link": "https://poll.cc/questions/94",
    "title": "Would you rather have 30 million dollars or 30 million loyal friends?"
  },
  {
    "link": "https://poll.cc/questions/95",
    "title": "Would you want to be notified whenever someone masutrbates thinking of you?"
  },
  {
    "link": "https://poll.cc/questions/96",
    "title": "Do you think Reddit is politically?"
  },
  {
    "link": "https://poll.cc/questions/98",
    "title": "Would you call yourself a ‘feminist’?"
  },
  {
    "link": "https://poll.cc/questions/99",
    "title": "Do you think automatically upvoting polls you vote in would be a good feature?"
  },
  {
    "link": "https://poll.cc/questions/110",
    "title": "Do you judge someone based on their political beliefs?"
  },
  {
    "link": "https://poll.cc/questions/116",
    "title": "Do you think having a nice car attracts girls/women?"
  },
  {
    "link": "https://poll.cc/questions/119",
    "title": "Would you risk naming your child 'helicopter fart attack' without being allowed to get it changed in the future, for a 75% chance of winning $76.55 billion?"
  },
  {
    "link": "https://poll.cc/questions/125",
    "title": "Do you usually make eye contact when talking to people?"
  },
  {
    "link": "https://poll.cc/questions/133",
    "title": "Do you think it‘s weird (bad) a place like hooters exist?"
  },
  {
    "link": "https://poll.cc/questions/137",
    "title": "Do you believe in dinosaurs existing?"
  },
  {
    "link": "https://poll.cc/questions/138",
    "title": "Do you eat spaghetti with or without spoon?"
  },
  {
    "link": "https://poll.cc/questions/143",
    "title": "Do you support the legalization of same-sex marriage?"
  },
  {
    "link": "https://poll.cc/questions/145",
    "title": "Would you rather be American or British?"
  },
  {
    "link": "https://poll.cc/questions/155",
    "title": "Do you think slavery should be taught in schools?"
  },
  {
    "link": "https://poll.cc/questions/156",
    "title": "Does your nation's flag contain the color red?"
  },
  {
    "link": "https://poll.cc/questions/162",
    "title": "Would you rather have?"
  },
  {
    "link": "https://poll.cc/questions/164",
    "title": "Do you enjoy kissing?"
  },
  {
    "link": "https://poll.cc/questions/165",
    "title": "Would you rather be morbidly obese or be dangerously underweight?"
  },
  {
    "link": "https://poll.cc/questions/166",
    "title": "Don't look it up: Who was the first Roman Emporor?"
  },
  {
    "link": "https://poll.cc/questions/174",
    "title": "Do you think that Arab numbers should be taught in American schools?"
  },
  {
    "link": "https://poll.cc/questions/175",
    "title": "Would you rather"
  },
  {
    "link": "https://poll.cc/questions/176",
    "title": "Do you like the United States of America?"
  },
  {
    "link": "https://poll.cc/questions/177",
    "title": "Do you think you could land a commercial airliner if both of the pilots were incapacitated?"
  },
  {
    "link": "https://poll.cc/questions/184",
    "title": "Would you go on a 14-hour flight to Australia where everyone including you, gets diarrhœa for 1,000,000 dollars?"
  },
  {
    "link": "https://poll.cc/questions/185",
    "title": "Would you nuke your own country to oblivion for $500,000,000,000?"
  },
  {
    "link": "https://poll.cc/questions/189",
    "title": "Do you think Taiwan should be an independent country?"
  },
  {
    "link": "https://poll.cc/questions/193",
    "title": "Would you rather earn $0.01 every time you took a step or earn $1 every time you jumped?"
  },
  {
    "link": "https://poll.cc/questions/196",
    "title": "Do you think dogs can be kept on a plant based diet?"
  },
  {
    "link": "https://poll.cc/questions/198",
    "title": "Would any single country be able to successfully invade &amp; conquer the United States?"
  },
  {
    "link": "https://poll.cc/questions/199",
    "title": "Do you speak another language besides your mother tongue?"
  },
  {
    "link": "https://poll.cc/questions/203",
    "title": "Do you think America is a safe place to live?"
  },
  {
    "link": "https://poll.cc/questions/80",
    "title": "Do you think allowing citizens to own guns makes life more or less safe?"
  },
  {
    "link": "https://poll.cc/questions/81",
    "title": "Do you read every poll option before answering?"
  },
  {
    "link": "https://poll.cc/questions/85",
    "title": "Do you believe in Astronomy?"
  },
  {
    "link": "https://poll.cc/questions/86",
    "title": "Would you date someone who is bisexual?"
  },
  {
    "link": "https://poll.cc/questions/90",
    "title": "Do you think America should switch to the metric system?"
  },
  {
    "link": "https://poll.cc/questions/105",
    "title": "Don’t you just hate it when someone only likes your question post instead of answering it ?"
  },
  {
    "link": "https://poll.cc/questions/112",
    "title": "Would you rather be…?"
  },
  {
    "link": "https://poll.cc/questions/115",
    "title": "Do you push the button?"
  },
  {
    "link": "https://poll.cc/questions/117",
    "title": "Do you write 'α' or 'a' ?"
  },
  {
    "link": "https://poll.cc/questions/121",
    "title": "Would you accept $1 million dollars if you have to: ?"
  },
  {
    "link": "https://poll.cc/questions/123",
    "title": "Do you think a man crying is weak ?"
  },
  {
    "link": "https://poll.cc/questions/126",
    "title": "Would you support a new law to remove ALL firearms and other weapons of war from ALL American private citizens?"
  },
  {
    "link": "https://poll.cc/questions/130",
    "title": "Would you vote for a politician that shares all your political stances even thought he/she had a history of punching journalists in the face?"
  },
  {
    "link": "https://poll.cc/questions/131",
    "title": "Do you play Raid Shadow Legends?"
  },
  {
    "link": "https://poll.cc/questions/132",
    "title": "Do you agree with traditional gender roles?"
  },
  {
    "link": "https://poll.cc/questions/134",
    "title": "Do you think healthcare is a human right?"
  },
  {
    "link": "https://poll.cc/questions/142",
    "title": "Do you think NASA invented thunderstorms to cover up the sound of space battles?"
  },
  {
    "link": "https://poll.cc/questions/146",
    "title": "Do you think it’s wrong to refer to a mixed gendered group of people as “guys”"
  },
  {
    "link": "https://poll.cc/questions/148",
    "title": "Do you think cigarettes should be banned?"
  },
  {
    "link": "https://poll.cc/questions/151",
    "title": "Would you rather be deaf, blind, or mute"
  },
  {
    "link": "https://poll.cc/questions/152",
    "title": "Do you think Kyle Rittenhouse should have been found guilty?"
  },
  {
    "link": "https://poll.cc/questions/154",
    "title": "Do you eat the crust on pizza?"
  },
  {
    "link": "https://poll.cc/questions/159",
    "title": "Do you prefer expressing temperature In Fahrenheit or Celsius?"
  },
  {
    "link": "https://poll.cc/questions/160",
    "title": "Do you live in the biggest city in your country? (Population wise)"
  },
  {
    "link": "https://poll.cc/questions/167",
    "title": "Would you rather have to?"
  },
  {
    "link": "https://poll.cc/questions/170",
    "title": "Would you put a mask on during a Zoom meeting if someone was uncomfortable with seeing maskless people?"
  },
  {
    "link": "https://poll.cc/questions/172",
    "title": "Would you rather meet The Paul brothers(Logan and Jake) or find $20"
  },
  {
    "link": "https://poll.cc/questions/173",
    "title": "Would your rather..."
  },
  {
    "link": "https://poll.cc/questions/178",
    "title": "Do you believe in Santa?"
  },
  {
    "link": "https://poll.cc/questions/181",
    "title": "Would you lick a bathroom floor for ______?"
  },
  {
    "link": "https://poll.cc/questions/187",
    "title": "Would you want your significant other to track down the slave owners who owned your family and invite them to your birthday party to apologize to you?"
  },
  {
    "link": "https://poll.cc/questions/192",
    "title": "Does Your Reddit Avatar Look Like You?"
  },
  {
    "link": "https://poll.cc/questions/195",
    "title": "Do you think it is okay for parents to *force* their kids to be vegan?"
  },
  {
    "link": "https://poll.cc/questions/89",
    "title": "Would you rather get $1,000,000 or $10,000, but if you choose the more popular option, you get nothing"
  },
  {
    "link": "https://poll.cc/questions/93",
    "title": "Do you think that there COULD BE an ancient advanced civilisation that we have no clue about?"
  },
  {
    "link": "https://poll.cc/questions/97",
    "title": "Do you think america should make owning a gun illegal?"
  },
  {
    "link": "https://poll.cc/questions/101",
    "title": "Would you rather your pet dies, or ten random children in another country die?"
  },
  {
    "link": "https://poll.cc/questions/106",
    "title": "Do you know the meaning of your first name?"
  },
  {
    "link": "https://poll.cc/questions/107",
    "title": "Do you believe the term \"birthing person\" should be used to replace \"pregnant women\"?"
  },
  {
    "link": "https://poll.cc/questions/113",
    "title": "Would you rather be called..."
  },
  {
    "link": "https://poll.cc/questions/124",
    "title": "Do Over Due to Poll Fail: What is the Most You Will Pay For a Reddit Avatar?"
  },
  {
    "link": "https://poll.cc/questions/128",
    "title": "Do you have any of these paid services?"
  },
  {
    "link": "https://poll.cc/questions/129",
    "title": "Would you rather be a..."
  },
  {
    "link": "https://poll.cc/questions/139",
    "title": "Do you wash your butthole when bathing?"
  },
  {
    "link": "https://poll.cc/questions/140",
    "title": "Do you consider someone being a patriotic American a positive or negative thing?"
  },
  {
    "link": "https://poll.cc/questions/147",
    "title": "Would you rather have a roommate who is far left, or one who is far right?"
  },
  {
    "link": "https://poll.cc/questions/149",
    "title": "Would you pick cotton by hand for $200 an hour?"
  },
  {
    "link": "https://poll.cc/questions/150",
    "title": "Do you like to live in your country?"
  },
  {
    "link": "https://poll.cc/questions/153",
    "title": "Does the world need more veganism?"
  },
  {
    "link": "https://poll.cc/questions/161",
    "title": "Do you have vision problems"
  },
  {
    "link": "https://poll.cc/questions/163",
    "title": "Would you choose the superpower?"
  },
  {
    "link": "https://poll.cc/questions/179",
    "title": "Would you judge a person based on their religion?"
  },
  {
    "link": "https://poll.cc/questions/180",
    "title": "Would you auction your virginity for $1,000,000+?"
  },
  {
    "link": "https://poll.cc/questions/200",
    "title": "Would you shave your daughters head if she was bullying a bald girl with cancer?"
  },
  {
    "link": "https://poll.cc/questions/204",
    "title": "Would you ever date a nonbinary person?"
  },
  {
    "link": "https://poll.cc/questions/207",
    "title": "Do you consider Russia a superpower?"
  },
  {
    "link": "https://poll.cc/questions/208",
    "title": "Would you rather it always be +40C or - 40C?"
  },
  {
    "link": "https://poll.cc/questions/209",
    "title": "With the recent discussions around climate change and its impact on global policies, here's a poll idea: \n\n\"Do you think governments worldwide are doing enough to combat climate change? 🌍 Yes or No? #ClimateAction #Poll\"\n\n(Characters: 142)"
  },
  {
    "link": "https://poll.cc/questions/210",
    "title": "Should social media companies be legally responsible for the content shared on their platforms? Yes or No? #SocialMedia #Poll"
  },
  {
    "link": "https://poll.cc/questions/211",
    "title": "Today's hot topic revolves around the debate on AI regulation. Here's a poll idea for you:\n\n\"Should governments impose strict regulations on AI development to ensure ethical use? 🤖💼 #AIDebate #Poll\"\n\nYes or No?"
  },
  {
    "link": "https://poll.cc/questions/212",
    "title": "Today's hot topic: \"With the rise of AI technology, should there be stricter regulations on its development and use?\" Yes or No? #AIEthics #Poll"
  },
  {
    "link": "https://poll.cc/questions/213",
    "title": "Today's interesting topic for a Twitter poll could be about the ongoing debates surrounding social media privacy. Here’s a suggestion:\n\n\"Do you believe social media platforms should be legally required to protect user privacy even if it limits their ability to make profits? 🤔 #Privacy #SocialMedia #Poll\" \n\n(Yes or No) \n\nCharacter count: 215"
  },
  {
    "link": "https://poll.cc/questions/214",
    "title": "Today's controversial topic: \"Do you believe AI technology should have regulations to ensure safety and ethical use?\" Yes or No? #AIEthics #TechDebate"
  },
  {
    "link": "https://poll.cc/questions/215",
    "title": "Should social media platforms be required to verify the identities of all users to reduce misinformation? Yes or no? #SocialMedia #Misinformation"
  },
  {
    "link": "https://poll.cc/questions/216",
    "title": "Today's controversial topic is the use of AI in creative industries. Here's a poll idea for you:\n\n\"Do you believe AI should have a role in creating art and music? 🎨🎶 Yes or No? #AI #CreativeArts\" \n\n(Characters: 158)"
  },
  {
    "link": "https://poll.cc/questions/217",
    "title": "Today's most engaging topic could be about the ongoing debates around AI and its impact on jobs. You could post a poll like this:\n\n\"Do you think AI will create more jobs than it eliminates in the next decade? 🤖💼 #AI #FutureOfWork #Poll #Tech\"\n\n(Here, the character count is 156, well within the limit!)"
  },
  {
    "link": "https://poll.cc/questions/218",
    "title": "Should social media platforms have stricter regulations to combat misinformation? Yes or No? #Poll #Misinformation #SocialMedia "
  },
  {
    "link": "https://poll.cc/questions/219",
    "title": "Today's engaging topic revolves around the ongoing debate on climate change policies. Here's a poll suggestion:\n\n\"Do you believe that implementing stricter climate change policies will significantly impact global warming? Yes or No? #ClimateAction #Poll\"\n\n(Characters: 144)"
  },
  {
    "link": "https://poll.cc/questions/220",
    "title": "Should social media platforms regulate political content to combat misinformation? Yes or No? #Poll #Misinformation"
  },
  {
    "link": "https://poll.cc/questions/221",
    "title": "Today's hot topic: \"Do you believe social media platforms should regulate misinformation more strictly?\" Yes or No? #Poll #SocialMedia #Misinformation"
  },
  {
    "link": "https://poll.cc/questions/222",
    "title": "Today's engaging topic could be: \"With ongoing debates about climate change, do you believe government action is essential for combating environmental issues? Yes or No?\" #ClimateAction #Poll"
  },
  {
    "link": "https://poll.cc/questions/223",
    "title": "Today's controversial topic for a poll could be about AI and its impact on jobs. Here’s a suggestion:\n\n\"Do you believe AI will create more jobs than it replaces in the next decade? 🤖💼 #AIJobImpact #FutureOfWork Yes or No?\" \n\n(Characters: 137)"
  },
  {
    "link": "https://poll.cc/questions/224",
    "title": "Should governments implement stricter regulations on AI development to ensure safety and ethical standards?"
  },
  {
    "link": "https://poll.cc/questions/225",
    "title": "Is it time to ban gas-powered cars in favor of electric vehicles to fight climate change?"
  },
  {
    "link": "https://poll.cc/questions/235",
    "title": "Will you vote for Trump?"
  },
  {
    "link": "https://poll.cc/questions/236",
    "title": "test question"
  },
  {
    "link": "https://poll.cc/questions/238",
    "title": "Can I continue  voting ?"
  },
  {
    "link": "https://poll.cc/questions/240",
    "title": "Do you like JD Vance as a VP?"
  },
  {
    "link": "https://poll.cc/questions/245",
    "title": "Do you think Mass deportation could deprive US builders of badly needed workers?"
  },
  {
    "link": "https://poll.cc/questions/246",
    "title": "You voted for Trump?"
  },
  {
    "link": "https://poll.cc/questions/247",
    "title": "Are you happy that Donald Trump has won the election ? "
  },
  {
    "link": "https://poll.cc/questions/249",
    "title": "Is climate change real?"
  },
  {
    "link": "https://poll.cc/questions/257",
    "title": "Do you support appointment of Matt Gaetz to Atterney General? "
  },
  {
    "link": "https://poll.cc/questions/258",
    "title": "Do you support Tulsi Gabbard pick for the department of national intelligence?"
  },
  {
    "link": "https://poll.cc/questions/259",
    "title": "Do you think Biden voted for Trump?😆"
  },
  {
    "link": "https://poll.cc/questions/260",
    "title": "Do you support Marine Le Pen?"
  },
  {
    "link": "https://poll.cc/questions/261",
    "title": "Tony Fauci should be tried and imprisoned for crimes against humanity?"
  },
  {
    "link": "https://poll.cc/questions/262",
    "title": "Was arresting Tommy Robinson under the Terrorism Act 2000 an abuse of police power?"
  },
  {
    "link": "https://poll.cc/questions/263",
    "title": "Pardon @Snowden?"
  },
  {
    "link": "https://poll.cc/questions/265",
    "title": "Will you vote for Nancy Pelosi in 2026 reelection?"
  },
  {
    "link": "https://poll.cc/questions/266",
    "title": "Do you want Trump to select Howard Lutnick to run the US Treasury?"
  },
  {
    "link": "https://poll.cc/questions/267",
    "title": "Is direct democracy better then representative?"
  },
  {
    "link": "https://poll.cc/questions/268",
    "title": "Do you think we need centralized place online where people will publicly declare their standing on social or political issues?"
  },
  {
    "link": "https://poll.cc/questions/269",
    "title": "Will Donald Trump's tariff plan help the U.S. economy?"
  },
  {
    "link": "https://poll.cc/questions/270",
    "title": "Is capitalism better then communism?"
  },
  {
    "link": "https://poll.cc/questions/271",
    "title": "Is capitalism an ideal system?"
  },
  {
    "link": "https://poll.cc/questions/272",
    "title": "Call a General Election in UK?"
  },
  {
    "link": "https://poll.cc/questions/273",
    "title": "do you think West retaliate directly if Russia drops nukes?"
  },
  {
    "link": "https://poll.cc/questions/275",
    "title": "Do you support Elon Musk?"
  },
  {
    "link": "https://poll.cc/questions/276",
    "title": "Should social media platforms implement stricter regulations on misinformation?"
  },
  {
    "link": "https://poll.cc/questions/277",
    "title": "Do you think ads on reddit will be successful for poll.cc?"
  },
  {
    "link": "https://poll.cc/questions/278",
    "title": "Does the end justifies the means?"
  },
  {
    "link": "https://poll.cc/questions/279",
    "title": "Do you think homogeneous societies are better to live in?"
  },
  {
    "link": "https://poll.cc/questions/280",
    "title": "Do you think democracy is the best system?"
  },
  {
    "link": "https://poll.cc/questions/281",
    "title": "Do you like your work?"
  },
  {
    "link": "https://poll.cc/questions/282",
    "title": "Do you think US should ban guns?"
  },
  {
    "link": "https://poll.cc/questions/283",
    "title": "Are you free speech absolutist?"
  },
  {
    "link": "https://poll.cc/questions/284",
    "title": "Should members of Congress be banned from stock trading?"
  },
  {
    "link": "https://poll.cc/questions/285",
    "title": "Would you ban all Chinese social media in United States until they unban all United states social media companies?"
  },
  {
    "link": "https://poll.cc/questions/286",
    "title": "Senator Brian Schatz has introduced bipartisan legislation to ban social media for people under the age of 13.\n\nDo you support this?"
  },
  {
    "link": "https://poll.cc/questions/287",
    "title": "Do you think transgender operations should be allowed for people under 18?"
  },
  {
    "link": "https://poll.cc/questions/288",
    "title": "Do you think using tariffs can be used to ensure your country is being treated fairly on world stage? "
  },
  {
    "link": "https://poll.cc/questions/289",
    "title": "Do you think state and church should be separate?"
  },
  {
    "link": "https://poll.cc/questions/290",
    "title": "Do you think It's ok or even good for president of your country to use tariffs as a measure of protecting your country economy?"
  },
  {
    "link": "https://poll.cc/questions/291",
    "title": "Do you think we should bring back public executions?"
  },
  {
    "link": "https://poll.cc/questions/292",
    "title": "Do you like cats ? "
  },
  {
    "link": "https://poll.cc/questions/11",
    "title": "New question?"
  }
]