import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'
import decodeJwt from 'jwt-decode'
import projectXUI from 'project-x-ui'

import GroupItem from './components/GroupItem'

import { useSrpcApi } from '../../../../../../hooks/useSrpcApi'

import { SET_SELECTED_GROUP_ID } from '../../../../../../actions/selectedGroupId'
import { SET_SELECTED_GROUP_USERS } from '../../../../../../actions/selectedGroupUsers'

import { useGroups } from '../../../../../../context/groups'

const {
  leafs: { VennDiagram }
} = projectXUI

export const GroupList = ({ jwtMock }) => {
  const [jwtClient] = useLocalStorage('jwt')
  const jwt = process.env.SERVER ? jwtMock : jwtClient
  const srpcApi = useSrpcApi()
  const dispatch = useDispatch()
  const { groups, setGroups, newGroup } = useGroups()

  const selectedForComposition = useSelector(state => state.selectedForCompositionGroupIds)
  const selectedGroupId = useSelector(state => state.selectedGroupId)

  const user = jwt ? decodeJwt(jwt) : null

  useEffect(() => {
    srpcApi.getGroups({ jwt })
      .then(({ groups }) => {
        const filteredGroups = user ? groups.filter(i => i.userId === user?.userId) : groups
        setGroups(filteredGroups)
        const selectedGroup = groups.find(g => g.selected)
        if (selectedGroup) {
          dispatch({ type: SET_SELECTED_GROUP_ID, payload: selectedGroup.id })
          dispatch({ type: SET_SELECTED_GROUP_USERS, payload: selectedGroup.users })
        }
      })
  }, [])

  const groupsToDisplay = (selectedForComposition.length === 2
    ? [...selectedForComposition.map(g => ({ ...g, ...(groups || []).find(i => i.id === g.id) })), newGroup]
    : selectedForComposition.length === 1
      ? (groups || [])
        .map(g => selectedForComposition.find(i => i.id === g.id)
          ? ({ ...g, color: selectedForComposition.find(i => i.id === g.id).color })
          : g
        )
      : [newGroup, ...(groups?.map(g => ({ ...g, selected: g.id === selectedGroupId })) || [])]).filter(Boolean)

  if (!groups) {
    return null
  }

  if (groupsToDisplay && groupsToDisplay.length === 0) {
    return (
      <div
        style={{
          fontFamily: 'IBM Plex Sans',
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          top: '50%',
          textAlign: 'center'
        }}
      >
        <span style={{
          fontSize: 18,
          marginBottom: 8
        }}>Empty</span>
        <p
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            whiteSpace: 'nowrap',
            color: '#b3b3b3'
          }}
        >
          {!user ? 'Login and ' : ''} {!user ? 'C' : 'c'}lick on <VennDiagram style={{ margin: '0 4px' }} fill='#b3b3b3' /> on poll to create a group
        </p>
      </div>
    )
  }

  return groupsToDisplay.map(g => <GroupItem key={g.id || 'new'} group={g} />)
}
