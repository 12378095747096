import { combineReducers } from 'redux'

import circleParts from './circleParts'
import selectedGroupId from './selectedGroupId'
import selectedForCompositionGroupIds from './selectedForCompositionGroupIds'
import selectedGroupUsers from './selectedGroupUsers'
import usersByAnswer from './usersByAnswer'
import usersQuestionsAndAnswers from './userQuestionsAndAnswers'
import questionsSearchResult from './questionsSearchResult'
import usersSearchResult from './usersSearchResult'
import sortAndFilterForMessages from './sortAndFilterForMessages'

const reducer = combineReducers({
  circleParts,
  selectedGroupId,
  selectedGroupUsers,
  selectedForCompositionGroupIds,
  usersByAnswer,
  usersQuestionsAndAnswers,
  questionsSearchResult,
  usersSearchResult,
  sortAndFilterForMessages
})

export default reducer
