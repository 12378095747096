import React, { useEffect, useState, useCallback } from 'react'
import { Provider } from 'react-redux'
import projectXUI from 'project-x-ui'
import { useLocalStorage } from '@rehooks/local-storage'
import { Toaster } from 'react-hot-toast'
import decodeJwt from 'jwt-decode'
import useCookie from 'react-use-cookie'
import { Routes, Route, useSearchParams, useNavigate, Navigate } from 'react-router-dom'
import ReactGA from 'react-ga4'
import _ from 'lodash'

import AuthorizedScreen from './components/AuthenticatedScreen'
import { QuestionSearch } from './components/AuthenticatedScreen/components/QuestionSearch'
import { UserSearch } from './components/AuthenticatedScreen/components/UsersSearch'
import { UserHistory } from './components/AuthenticatedScreen/components/UserHistory'
import { UsersByAnswer } from './components/AuthenticatedScreen/components/UsersByAnswer'
import { QuestionDetails } from './components/AuthenticatedScreen/components/QuestionDetails'
import { handleTwitterLogin } from './components/AuthenticatedScreen/components/ActionsPanel/ActionsPanel'

import { useMixpanel, MixpanelProvider } from '../../hooks/mixpanel'

import { useSrpcApi } from '../../hooks/useSrpcApi'
import { GroupsProvider } from '../../context/groups'

import store from '../../store'

import keywords from './keywords.json'
import questions from '../../../../questions.json'

// import 'react-spring-bottom-sheet/dist/style.css'

const {
  shallow: { Body, Landing },
  leafs: { LeftMenuBar }
} = projectXUI

const TRACKING_ID = 'G-4LRDGG17KY'

ReactGA.initialize(TRACKING_ID)

const Test = ({ jwtMock, initialQuestion, featureFlagsFromServer, isBot, url }) => {
  const [jwtClient, setLSJWT] = useLocalStorage('jwt')
  const [, setRealJWT] = useLocalStorage('jwt_real')
  const [hideSwipeHintClient, setHideSwipeHint] = useLocalStorage('hideSwipeHint')
  const [, setCookieJWT] = useCookie('jwt', '0')
  const [searchParams, setSearchParams] = useSearchParams()
  const { mixpanel } = useMixpanel()

  const searchParamsObject = Object.fromEntries(searchParams)

  useEffect(() => {
    if (searchParamsObject.jwt) {
      setLSJWT(searchParamsObject.jwt)
      setCookieJWT(searchParamsObject.jwt, { days: 100000 })
      setRealJWT(searchParamsObject.jwt)
      setSearchParams({})
    }
  }, [searchParamsObject.jwt])

  useEffect(() => {
    if (searchParamsObject.gclid && mixpanel.current) {
      mixpanel.current.people.set({
        gclid: searchParamsObject.gclid
      })
    }
  }, [searchParamsObject.gclid, Boolean(mixpanel.current)])

  const [address, setAddress] = useState()
  const [payout, setPayout] = useState(0)

  const srpcApi = useSrpcApi()

  const jwt = process.env.SERVER ? jwtMock : jwtClient
  const hideSwipeHint = process.env.SERVER ? jwtMock : hideSwipeHintClient
  const featureFlags = process.env.SERVER ? featureFlagsFromServer : window.featureFlags
  const myUrl = process.env.SERVER ? url : location.pathname

  let user = null
  if (jwt) {
    user = decodeJwt(jwt)
  }
  useEffect(() => {
    if (user && mixpanel.current) {
      mixpanel.current.identify(user._id)
      mixpanel.current.people.set({
        $name: user.name,
        $email: user.email
      })
    }
  }, [user, Boolean(mixpanel.current)])

  const showSwipeHint = (user && user.new) && !hideSwipeHint

  const navigate = useNavigate()

  // const connectToWallet = useCallback(() => {
  //   if (!user) {
  //     return
  //   }

  //   Promise.all([import('@wagmi/core'), import('@wagmi/connectors'), import('../../config')])
  //     .then(async ([wagmiCore, wagmiConnectors, { config: configPromise }]) => {
  //       const config = await configPromise
  //       await wagmiCore.connect(config, {
  //         connector: wagmiConnectors.walletConnect({
  //           projectId: 'fe7e5bcbefc1e04f395a0774add234d1'
  //         })
  //       })
  //       console.log('wallet connected')

  //       if (!user.address) {
  //         const account = await wagmiCore.getAccount(config)
  //         const result = await srpcApi.saveUserCryptoAddress({ jwt, address: account.address })
  //         mixpanel.current.track('Wallet Connected', {
  //           address: account.address
  //         })
  //         setLSJWT(result.jwt)
  //         setCookieJWT(result.jwt, { days: 100000 })
  //         setRealJWT(result.jwt)
  //       }
  //     })
  // }, [user])

  const hide = async () => {
    const result = await srpcApi.hideWalletModal({ jwt })
    setLSJWT(result.jwt)
    setCookieJWT(result.jwt, { days: 100000 })
    setRealJWT(result.jwt)
  }

  const createUser = async ({ email, name, answer, picture, parentMessageId, shortId, sendEmails }) => {
    const { jwt, error } = await srpcApi.createUserNew({ email, name, picture, answer, shortId, parentMessageId, sendEmails })
    if (!error) {
      if (answer) {
        mixpanel.current.track('Sign Up Success', {
          type: 'question-answer',
          questionId: shortId
        })
        navigate(`/questions/${shortId}/?jwt=${jwt}`)
      } else {
        navigate(`/?jwt=${jwt}`)
      }
    }
  }

  const updateJwt = async () => {
    const result = await srpcApi.getUserToken({ jwt })
    setLSJWT(result.jwt)
    setCookieJWT(result.jwt, { days: 100000 })
    setRealJWT(result.jwt)
  }

  const addImage = (messageId, img) => {
    srpcApi.addImage({ jwt, img, messageId })
  }

  const actualIsBot = isBot || (!process.env.SERVER && window.isBot)

  const selectUser = async (user) => {
    const userId = typeof user === 'string' ? user : user._id

    navigate(`/users/${userId}`)
  }

  return (
    <>
      <Provider store={store}>
        <Body
          address={address}
          userId={user?._id}
          payout={payout}
          includeSwipes
          showSwipeHint={showSwipeHint}
          hideSwipeHint={() => (!hideSwipeHint && (user && user.new)) && setHideSwipeHint(true)}
          connectToWallet={featureFlags?.rewards ? connectToWallet : () => {}}
          connected={user?.address}
          isWalletModalOpenInitial={false}
          hide={hide}
          createUser={createUser}
          handleTwitterLogin={handleTwitterLogin}
          updateJwt={user?.verifiedKYC ? null : updateJwt}
          addImage={addImage}
        >
          <GroupsProvider>
            <Toaster position='top-right' containerClassName='test-toast' />
            <Routes>
              <Route
                index
                element={jwt
                  ? <>
                      <LeftMenuBar user={user} showMyHistory={() => selectUser(user)}/>
                      <AuthorizedScreen setPayout={setPayout} setAddress={setAddress} jwt={jwt} />
                    </>
                  : <Landing jwt={jwt} />
                }
              />
              {!jwt && <Route path="/app" index element={<><LeftMenuBar user={user} showMyHistory={() => selectUser(user)} /><AuthorizedScreen jwt={jwt} /></>} />}
              {jwt && <Route path="/about" index element={<Landing jwt={jwt} />} />}
              <Route path='/sitemap' element={!actualIsBot
                ? <Navigate to="/" replace />
                : (
                    <ul>
                      {keywords.map(({ keyword }) => <li key={keyword}><a href={keyword.replaceAll(' ', '-')}>{keyword}</a></li>)}
                      {questions.map(({ link, title }) => <li key={title}><a href={link}>{title}</a></li>)}
                    </ul>
                  )
              }/>
              {keywords.map(({ keyword, description }) => {
                return (
                  <Route
                    path={keyword.replace(/\s+/g, '-').toLowerCase()}
                    key={_.capitalize(keyword)}
                    element={<Landing jwt={jwt} isBot={actualIsBot} title={keyword} description={description} />}
                  />
                )
              })}

              <Route path="/questions" element={<><LeftMenuBar user={user} showMyHistory={() => selectUser(user)} /><QuestionSearch jwt={jwt} /></>} />
              <Route path="/questions/:id" element={<><LeftMenuBar user={user} showMyHistory={() => selectUser(user)} /><QuestionDetails initialQuestion={initialQuestion} jwt={jwt} /></>} />
              <Route path="/questions/:questionId/:answer/users" element={<><LeftMenuBar user={user} showMyHistory={() => selectUser(user)} /><UsersByAnswer jwt={jwt} /></>} />

              <Route path="/users" element={<><LeftMenuBar user={user} showMyHistory={() => selectUser(user)} /><UserSearch jwt={jwt} /></>} />
              <Route path="/users/:name" element={<><LeftMenuBar user={user} showMyHistory={() => selectUser(user)} /><UserHistory jwt={jwt} /></>} />
              <Route
                path="*"
                element={<Navigate to="/" replace />}
              />
            </Routes>
            {actualIsBot ? <a className='sitemap' href='/sitemap'>Sitemap</a> : null}
          </GroupsProvider>
        </Body>
      </Provider>
    </>
  )
}

export default ({ jwtMock, featureFlags, isBot, url, initialQuestion }) => (
  <MixpanelProvider>
    <Test jwtMock={jwtMock} featureFlagsFromServer={featureFlags} isBot={isBot} url={url} initialQuestion={initialQuestion} />
  </MixpanelProvider>
)
