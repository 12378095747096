import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import projectXUI from 'project-x-ui'

import { SET_CIRCLE_PARTS } from '../../../../../../actions/circleParts'

const { leafs: { Circles } } = projectXUI

export const GroupComposition = () => {
  const selectedForComposition = useSelector(state => state.selectedForCompositionGroupIds)
  const dispatch = useDispatch()

  if (selectedForComposition.length === 0) {
    return null
  }

  const setCircleParts = circleParts =>
    dispatch({ type: SET_CIRCLE_PARTS, payload: circleParts })

  return (
    <Circles
      selectedGroups={selectedForComposition}
      handleCompositionTypeChange={setCircleParts}
    />
  )
}

export default GroupComposition
