import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'
import projectXUI from 'project-x-ui'

import { useSrpcApi } from '../../../../../../../../hooks/useSrpcApi'

import { SET_SELECTED_FOR_COMPOSITION_GROUP_IDS } from '../../../../../../../../actions/selectedForCompositionGroupIds'
import { SET_SELECTED_GROUP_ID } from '../../../../../../../../actions/selectedGroupId'
import { SET_CIRCLE_PARTS } from '../../../../../../../../actions/circleParts'

import { useGroups } from '../../../../../../../../context/groups'

const { leafs: { GroupCard: GroupCardUI } } = projectXUI

const colors = ['#d24a43', '#3eb5f1']

const circlePartsToCompositionType = circleParts => {
  if (circleParts.includes('intersection') && circleParts.length === 1) {
    return 'intersection'
  }
  if (circleParts.includes('leftWing') && circleParts.length === 1) {
    return 'difference'
  }
  if (circleParts.includes('rightWing') && circleParts.length === 1) {
    return 'difference'
  }
  if (circleParts.includes('rightWing') && circleParts.includes('leftWing') && circleParts.length === 2) {
    return 'symmetric-difference'
  }
  if (['leftWing', 'intersection', 'rightWing'].every(i => circleParts.includes(i))) {
    return 'union'
  }

  return null
}

export const GroupItem = ({ group, jwtMock }) => {
  const [jwtClient] = useLocalStorage('jwt')
  const jwt = process.env.SERVER ? jwtMock : jwtClient

  const circleParts = useSelector(state => state.circleParts)
  const selectedForComposition = useSelector(state => state.selectedForCompositionGroupIds)
  const selectedGroupId = useSelector(state => state.selectedGroupId)
  const { setNewGroup, newGroup, addGroup } = useGroups()

  const dispatch = useDispatch()

  const srpcApi = useSrpcApi()

  const compositionType = circlePartsToCompositionType(circleParts)

  const createSimpleGroup = async name => {
    const { group: newGroup } = await srpcApi.createGroup({ jwt, ...group, name })

    addGroup(newGroup)
    setNewGroup(null)
  }

  const createCompositeGroup = async name => {
    let groupIdLeft = selectedForComposition[0].id
    let groupIdRight = selectedForComposition[1].id

    if (compositionType === 'difference' && circleParts[0] === 'rightWing') {
      groupIdLeft = selectedForComposition[1].id
      groupIdRight = selectedForComposition[0].id
    }

    const { group: newGroup } = await srpcApi.createCompositeGroup({
      jwt,
      groupIdLeft,
      groupIdRight,
      compositionType,
      name
    })

    addGroup(newGroup)
    setNewGroup(null)
    dispatch({ type: SET_SELECTED_FOR_COMPOSITION_GROUP_IDS, payload: [] })
  }

  const createGroup = selectedForComposition.length === 2 ? createCompositeGroup : createSimpleGroup

  const combine = async () => {
    const newGroup = {
      id: group.id,
      color: selectedForComposition.length ? (selectedForComposition[0].color === colors[1] ? colors[0] : colors[1]) : colors[0]
    }

    const newSelectedForCompositionGroupIds =
      selectedForComposition.find(i => i.id === group.id)
        ? selectedForComposition.filter(i => i.id !== group.id)
        : [...selectedForComposition, newGroup]

    if (newSelectedForCompositionGroupIds.length === 2) {
      setNewGroup({ users: [], color: '#92278f' })
    }

    if (newSelectedForCompositionGroupIds.length === 0) {
      setNewGroup(null)
      dispatch({ type: SET_CIRCLE_PARTS, payload: [] })
    }

    dispatch({
      type: SET_SELECTED_FOR_COMPOSITION_GROUP_IDS,
      payload: newSelectedForCompositionGroupIds
    })
  }

  const toggleGroupSelection = () => {
    if (group.id === undefined) {
      setNewGroup(null)
      dispatch({
        type: SET_SELECTED_FOR_COMPOSITION_GROUP_IDS,
        payload: []
      })
      dispatch({
        type: SET_CIRCLE_PARTS,
        payload: []
      })
      return
    }
    srpcApi.setSelectedGroup({ jwt, groupId: group.id })
    dispatch({ type: SET_SELECTED_GROUP_ID, payload: selectedGroupId === group.id ? null : group.id })
  }

  const cancel = () => {
    if (selectedForComposition.length) {
      dispatch({ type: SET_CIRCLE_PARTS, payload: [] })
      dispatch({ type: SET_SELECTED_FOR_COMPOSITION_GROUP_IDS, payload: [] })
    }
    setNewGroup(null)
  }

  useEffect(() => {
    if (newGroup && (group.id === newGroup.id) && newGroup.color) {
      if (compositionType) {
        srpcApi.getCompositeGroupUserCount({
          jwt,
          groupIdLeft: selectedForComposition[0].id,
          groupIdRight: selectedForComposition[1].id,
          compositionType
        })
          .then(({ users }) => {
            setNewGroup({ users, color: '#92278f' })
          })
      } else {
        setNewGroup({ users: [], color: '#92278f' })
      }
    }
  }, [compositionType])

  return (
    <GroupCardUI
      disableButtons={!jwt}
      readyToSave={selectedForComposition.length ? compositionType : true}
      save={createGroup}
      toggleSelection={selectedForComposition.find(i => i.id === group.id) ? combine : toggleGroupSelection}
      combine={combine}
      cancel={cancel}
      color={group.selected ? '#bebebe' : group.color}
      userCount={group.users.length}
      {...group}
    />
  )
}
