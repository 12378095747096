import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Snap from 'snapsvg-cjs'

import Root from './components/Root'

import 'project-x-ui/dist/main.css'
import 'react-spring-bottom-sheet/dist/style.css'

import './styles.css'

window.Snap = Snap

hydrateRoot(
  document.querySelector('#app'),
  <BrowserRouter
    future={{
      v7_startTransition: true,
      v7_relativeSplatPath: true
    }}
  >
      <Root />
  </BrowserRouter>
)
